import React, { Component } from "react";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton,
} from 'react-accessible-accordion';
// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';


class Accordion01 extends Component {
    render(){
        return(
            <Accordion className="accodion-style--1" preExpanded={'0'}>
                <AccordionItem >
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Dein Unternehmen hört nie auf, sich zu verbessern.
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>Das bedeutet, Sie müssen innovativ sein, den Markt beobachten, digitale Technologien verstehen und einsetzen und gleichzeitig die Kompetenzen in Ihrem Unternehmen ausbauen – alles in allem ein Aufgabenprofil, das alleine kaum zu bewältigen ist!</p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                          Die einzige Konstante im Leben ist die Veränderung
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>Hier kommen wir ins Spiel, mit unserer Trendbasierten Innovationsberatung helfen wir Ihnen, Ihr Unternehmen in der digitalen Welt auf Erfolgskurs zu bringen, einfach, pragmatisch und mit der erforderliche Hingabe, dass Sie mit uns keine entscheidende innovative Entwicklung im Markt verpassen.</p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Erfolgreich durch den Digitalen Wandel
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>Wir erarbeiten mit Ihnen ein innovatives und attraktives Konzept zur Digitalisierung Ihres Geschäftsmodells, das Sie erfolgreich auf allen passenden Kanälen repräsentiert. Durch Automatisierung erreichen Sie mehr in kürzerer Zeit. Anschließend kümmern wir uns um eine einwandfreie Umsetzung, die Ihre Kunden und Geschäftspartner begeistern wird.</p>
                    </AccordionItemPanel>
                </AccordionItem>

            </Accordion>
        )
    }
}

export default Accordion01;





