import React, { Component } from "react";

class BrandTwo extends Component{
    render(){
        return(
            <React.Fragment>
                <ul className="brand-style-2">
                  <li>
                    <img src="/assets/images/portfolio/vehicl_sw.png" alt="Logo Images"/>
                  </li>
                    <li>
                        <img src="/assets/images/portfolio/adm_sw.png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/portfolio/maskenversand_sw.png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/portfolio/agrano_sw.png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/portfolio/repairmondo_sw.png" alt="Logo Images"/>
                    </li>

                </ul>
            </React.Fragment>
        )
    }
}
export default BrandTwo;
