import React, { Component } from "react"
import { FiCast, FiLayers, FiUsers, FiMonitor } from "react-icons/fi"

import VehiclLogo from "../../../public/assets/images/portfolio/vehicl.svg"

const ServiceList = [
  {
    icon: <img src="/assets/images/portfolio/vehicl.svg" height="50" alt="VEHICL Logo"/>,
    title: "VEHICL GmbH",
    description: "Digitalisierte Prozesse und Lösungen für die Automobilindustrie.",
    url: "https://vehicl.com"
  },
  {
    icon: <FiLayers/>,
    title: "Gain & Glory GmbH",
    description: "Produktion und Vertrieb von Sportartikeln und Nahrungsergänzungsmitteln."
  },
  {
    icon: <img src="/assets/images/portfolio/edel.png" height="50" alt="VEHICL Logo"/>,
    title: "EDEL Marketing & Digitalgesellschaft mbH",
    description: "Marketing und Digitale Lösungen aus einer Hand."
  },
  {
    icon: <img src="/assets/images/portfolio/maskenversand.png" height="50" alt="Maskenversand Logo"/>,
    title: "Maskenversand",
    description: "Versandhandel für Produkte der Arbeitssicherheit.",
    url: "https://maskenversand.online"
  },
  {
    icon: <img src="/assets/images/portfolio/repairmondo.png" height="50" alt="Repairmondo Logo"/>,
    title: "Repairmondo",
    description: "Online-Werkstatt für Smartphones und Spielekonsolen.",
    url: "https://repairmondo.de"
  }
]


class ServiceThree extends Component {
  render() {
    const { column } = this.props
    const ServiceContent = ServiceList.slice(0, this.props.item)

    return (
      <React.Fragment>
        <div className="row">
          {ServiceContent.map((val, i) => (
            <div className={`${column}`} key={i}>
              <a href={val.url} target="_blank">
                <div className="service service__style--2">
                  <div className="icon">
                    {val.icon}
                  </div>
                  <div className="content">
                    <h3 className="title">{val.title}</h3>
                    <p>{val.description}</p>
                  </div>
                </div>
              </a>
            </div>
          ))}
        </div>
      </React.Fragment>
    )
  }
}

export default ServiceThree
