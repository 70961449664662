import React, { Component } from "react"
import { FiCast, FiLayers, FiUsers, FiMonitor } from "react-icons/fi"

const ServiceList = [
  {
    icon: <FiCast/>,
    title: "Virtualization",
    description: "Effizienz und Auslastung im Anwendungsbereich steigern - Kosten und Energieverbrauch senken. Unsere Experten überführen Ihre Applikationen in eine virtualisierte Umgebung."
  },
  {
    icon: <FiLayers/>,
    title: "Cyber Security",
    description: "Sind Ihre betrieblichen Daten jederzeit sicher vor unbefugtem Zugriff? Wir bieten Ihnen maßgeschneiderte Konzepte an und sichern Ihre IT ab."
  },
  {
    icon: <FiUsers/>,
    title: "Backup Solutions",
    description: "Der reibungslose Betrieb ist das A und O - schnelle wiederverfügbarkeit Ihrer Daten muss gewährleistet sein. Wir kümmern uns um Ihre Datensicherheit."
  },
  {
    icon: <FiMonitor/>,
    title: "Monitoring",
    description: "Betriebsstörungen minimieren - das ist unser Leitsatz im Echtzeitmonitoring. Wir überwachen Ihr IT-System und springen ein, sollte etwas schieflaufen."
  }
]

class ServiceConcept extends Component {
  render() {
    let title = "Services",
      description = "Wir erarbeiten mit Ihnen ein innovatives und attraktives Konzept zur Digitalisierung Ihres Geschäftsmodells, das Sie erfolgreich auf allen passenden Kanälen repräsentiert. Durch Automatisierung erreichen Sie mehr in kürzerer Zeit. Anschließend kümmern wir uns um eine einwandfreie Umsetzung, die Ihre Kunden und Geschäftspartner begeistern wird."
    return (
      <React.Fragment>
        {ServiceList.map((val, i) => (
          <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
            <a>
              <div className="service service__style--2">
                <div className="icon">
                  {val.icon}
                </div>
                <div className="content">
                  <h3 className="title">{val.title}</h3>
                  <p>{val.description}</p>
                </div>
              </div>
            </a>
          </div>
        ))}
      </React.Fragment>
    )
  }
}

export default ServiceConcept
