import React, { Component } from "react"
import { FiCast, FiLayers, FiUsers, FiMonitor } from "react-icons/fi"

const ServiceList = [
  {
    icon: <FiCast/>,
    title: "Digital Concepts",
    description: "Anders denken, gewohnte Pfade verlassen, offen an innovative Technologien herangehen. Wir erweitern Ihr Unternehmen um eine digitale Komponente."
  },
  {
    icon: <FiLayers/>,
    title: "Branding",
    description: "Bei der Positionierung Ihrer Marke gilt es einiges zu beachten. Gerne unterstützen wir Sie hierbei. Innovative Merkmale, mit denen Sie sich von der Konkurrenz abheben, sind unser Tagesgeschäft."
  },
  {
    icon: <FiUsers/>,
    title: "Business Modelling",
    description: "Wir begleiten Sie ganzheitlich bei der Digitalisierung Ihres Geschäftsmodells. Neue Technologien und agilere Prozesse bringen zahlreiche neue Möglichkeiten, die es zu bewerten gilt."
  },
  {
    icon: <FiMonitor/>,
    title: "Consulting",
    description: "Anders denken, gewohnte Pfade verlassen, offen an innovative Technologien herangehen. Gerne beraten wir Sie auch unabhängig von den vorgenannten Leistungen bei Ihren Projekten."
  }
]

class ServiceConcept extends Component {
  render() {
    let title = "Services",
      description = "Wir erarbeiten mit Ihnen ein innovatives und attraktives Konzept zur Digitalisierung Ihres Geschäftsmodells, das Sie erfolgreich auf allen passenden Kanälen repräsentiert. Durch Automatisierung erreichen Sie mehr in kürzerer Zeit. Anschließend kümmern wir uns um eine einwandfreie Umsetzung, die Ihre Kunden und Geschäftspartner begeistern wird."
    return (
      <React.Fragment>
        {ServiceList.map((val, i) => (
          <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
            <a>
              <div className="service service__style--2">
                <div className="icon">
                  {val.icon}
                </div>
                <div className="content">
                  <h3 className="title">{val.title}</h3>
                  <p>{val.description}</p>
                </div>
              </div>
            </a>
          </div>
        ))}
      </React.Fragment>
    )
  }
}

export default ServiceConcept
