import React, { Component } from "react";
import { FiCast , FiLayers , FiUsers , FiMonitor } from "react-icons/fi";
import ServiceConcept from "./ServiceConcept"
import TabsTwo from "../tab/TabTwo"

class ServiceWrapper extends Component{
    render(){
        let title = 'Services',
        description = 'Wir erarbeiten mit Ihnen ein innovatives und attraktives Konzept zur Digitalisierung Ihres Geschäftsmodells, das Sie erfolgreich auf allen passenden Kanälen repräsentiert. Durch Automatisierung erreichen Sie mehr in kürzerer Zeit. Anschließend kümmern wir uns um eine einwandfreie Umsetzung, die Ihre Kunden und Geschäftspartner begeistern wird.';
        return(
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-4 col-12">
                        <div className="section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--50">
                            <h2 className="title">{title}</h2>
                            <p>{description}</p>

                            <div className="service-btn d-sm-none d-md-block">
                                <a className="btn-transparent rn-btn-dark" href="/case-study/logistics">
                                    <span className="text">Case Study: Operative Umsetzung einer Digitalstrategie für ein Logistikunternehmen</span></a>
                            </div>
                            <div className="service-btn d-sm-none d-md-block">
                                <a className="btn-transparent rn-btn-dark" href="/case-study/automotive">
                                    <span className="text">Case Study: Einrichtung einer cloudbasierten Telefonanlage für ein Automotive Unternehmen</span></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 col-12 mt_md--50">
                        <div className="row service-one-wrapper">
                          <TabsTwo tabStyle="tab-style--1"></TabsTwo>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceWrapper;
