import React, { Component } from "react";

class Impress extends Component{
    constructor(props){
        super(props);
        this.state = {
            rnName: '',
            rnEmail: '',
            rnSubject: '',
            rnMessage: '',
        };
    }
    render(){
        return(
            <div className="contact-form--1">
                <div className="container">
                    <div className="row row--35 align-items-start">
                        <div className="col-lg-6 order-2 order-lg-1">
                            <div className="section-title text-left mb--50">
                                <h2 className="title" style={{color: '#0065b1'}}>Impressum.</h2>
                                <p className="description">
                                    EDEL Marketing- & Digitalgesellschaft mbH<br></br>
                                    Mainstr. 85 Block 20<br></br>
                                    41469 Neuss<br></br>
                                    Deutschland<br></br>
                                    <br></br><br/>
                                    <b>Ein Unternehmen der</b><br></br>
                                    EdelNetzwerk Beteiligungs UG (haftungsbeschränkt)<br></br>
                                    San-Remo-Str. 15<br></br>
                                    40545 Düsseldorf<br></br>
                                    Deutschland<br></br>
                                    <br></br>
                                    Tel.: 0211 - 9763 5549<br></br>
                                    E-Mail: info@edelnetzwerk.com<br></br>
                                    <br></br>
                                    Registergericht: Amtsgericht Düsseldorf<br></br>
                                    Registernummer: HRB 86687<br></br>
                                    <br></br>
                                    Geschäftsführer: Moritz Haendel<br></br>
                                    <br></br>
                                    Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz: Beantragt<br></br>
                                    <br></br>
                                    Plattform der EU-Kommission zur Online-Streitbeilegung: <a href="https://ec.europa.eu/odr">https://ec.europa.eu/odr</a><br></br>
                                    <br></br>
                                    Wir sind zur Teilnahme an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle weder verpflichtet noch bereit.
                                </p>
                            </div>

                        </div>
                        <div className="col-lg-6 order-1 order-lg-2">
                            <div className="thumbnail mb_md--30 mb_sm--30">
                                <img src="/assets/images/about/about-6.jpg" alt="trydo"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default Impress;
