import React, { Component } from "react"
import { FiCast, FiLayers, FiUsers, FiMonitor } from "react-icons/fi"

const ServiceList = [
  {
    icon: <FiLayers/>,
    title: "Communications",
    description: "Gerne bringen wir die Telekommunikation innerhalb Ihres Unternehmens auf den neusten Stand / ob kollaborative Arbeitssysteme oder Telefonanlage. Wir helfen weiter."
  },
  {
    icon: <FiLayers/>,
    title: "Workflow Optimization",
    description: "Wir optimieren Ihre Effizienz durch die Integration von innovativen Systemen, die Ihre Workflows erleichtern. Dazu setzen wir auf die Marktführer der Branche oder auch Individualentwicklungen."
  },
  {
    icon: <FiCast/>,
    title: "Web Development",
    description: "Wir gestalten Ihre Webseite, um Ihr Unternehmen und Ihre Angebote bestmöglich zu präsentieren. Gerne überführen wir Ihr Webdesign in eine funktionsfähige Webseite und realisieren Individualentwicklungen."
  },
  {
    icon: <FiUsers/>,
    title: "Cloud Integration",
    description: "Wir überführen Ihre bestehenden Applikationen und Services in eine clound-native Umgebung, wodurch sie erreichbarer und skalierbarer wird."
  }
]

class ServiceConcept extends Component {
  render() {
    let title = "Services",
      description = "Wir erarbeiten mit Ihnen ein innovatives und attraktives Konzept zur Digitalisierung Ihres Geschäftsmodells, das Sie erfolgreich auf allen passenden Kanälen repräsentiert. Durch Automatisierung erreichen Sie mehr in kürzerer Zeit. Anschließend kümmern wir uns um eine einwandfreie Umsetzung, die Ihre Kunden und Geschäftspartner begeistern wird."
    return (
      <React.Fragment>
        {ServiceList.map((val, i) => (
          <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
            <a>
              <div className="service service__style--2">
                <div className="icon">
                  {val.icon}
                </div>
                <div className="content">
                  <h3 className="title">{val.title}</h3>
                  <p>{val.description}</p>
                </div>
              </div>
            </a>
          </div>
        ))}
      </React.Fragment>
    )
  }
}

export default ServiceConcept
