import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
class Testimonial extends Component{
    render(){
        return(
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-12">
                        <Tabs>
                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>Die einzige Konstante im Leben ist die Veränderung
                                        </p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>Heraklit</span> - Philosoph</h6>
                                    </div>
                                </div>
                            </TabPanel>
</Tabs>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default Testimonial;
