import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import ServiceConcept from "../service/ServiceConcept"
import ServiceIntegration from "../service/ServiceIntegration"
import ServiceOperation from "../service/ServiceOperation"

class TabsTwo extends Component{
    render(){
        let
        tab1 = "Concept.",
        tab2 = "Integration.",
        tab3 = "Operation."
        const { tabStyle } = this.props
        return(
            <div>
                {/* Start Tabs Area */}
                <div className="tabs-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <Tabs>
                                    <TabList className={`${tabStyle}`}>
                                        <Tab>{tab1}</Tab>
                                        <Tab>{tab2}</Tab>
                                        <Tab>{tab3}</Tab>
                                    </TabList>

                                    <TabPanel>
                                        <div className="single-tab-content">
                                          <div className="row service-one-wrapper">
                                            <ServiceConcept></ServiceConcept>
                                          </div>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                         <div className="row service-one-wrapper">
                                          <ServiceIntegration></ServiceIntegration>
                                         </div>
                                       </div>
                                    </TabPanel>



                                    <TabPanel>
                                       <div className="single-tab-content">
                                         <div className="row service-one-wrapper">
                                            <ServiceOperation></ServiceOperation>
                                         </div>
                                       </div>
                                    </TabPanel>



                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Tabs Area */}
            </div>
        )
    }
}



export default TabsTwo;
